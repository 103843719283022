.enroll-container {
    overflow: scroll;
    height: 100vh;
    background-color: white;
    padding: 16px;
    padding-bottom: 200px;
}

.guide-container {
    min-height: 100vh;
    padding: 0;
    padding-bottom: 200px;
}

.guide-container > li {
    font-family: 'gilroy-semibold', serif;
}

.guide-button .subtitle,
.enroll-container .subtitle {
    font-family: 'gilroy-bold', serif;
    margin: 0;
}

.enroll-form {
    margin-top: 32px;
    margin-bottom: 32px;
}

.enroll-container > .subtitle {
    font-family: 'gilroy-medium', serif;
}

.radio-group {
    color: #171f29;
    margin: 16px;
}

.MuiFormControlLabel-root {
    margin-right: 32px !important;
}

.PrivateSwitchBase-root-76 {
    padding: 0 !important;
}

.radio-group .MuiTypography-body1 {
    margin-left: 8px;
}

.guide-button {
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    border-bottom: 1px solid #4e52d3;
}

.hm_hyperModalContentWrapper.fullscreen {
    height: 100vh !important;
    width: 100vw !important;
    border-radius: 0 !important;
    background-color: #1b1c53 !important;
}

.modal-close-icon {
    font-size: 32px;
    font-family: 'gilroy-bold', serif;
    color: #ffffff;
}

.guide-buttons {
    border-top: 1px solid #4e52d3;
}

.modal-container {
    padding-top: 52px;
    margin: 16px;
    padding-bottom: 52px;
}

.measure-card {
    height: 74px;
    box-shadow:
        0 1px 2px 0 rgba(6, 8, 10, 0.25),
        0 0 1px 0 rgba(6, 8, 10, 0.15);
    border: solid 1px var(#e8e9eb);
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 16px;
    justify-content: space-between;
    display: flex;
}

.measure-card h3 {
    margin: 0;
    font-weight: bold;
}

.measure-value {
    color: #171f29;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    text-align: right;
    border: 0;
}

.how-to-measure {
    white-space: pre-wrap;
    font-weight: bold;
}

.modal-close-icon-container:hover {
    /*this is empty on purpose, do not delete*/
}

.modal-close-icon-container {
    margin-top: 42px;
    transform: none !important;
}

.enrollment-guide-text {
    padding-left: 16px;
    font-family: 'gilroy-semibold', serif !important;
    line-height: 1.33;
    letter-spacing: 0.04px;
    font-size: 18px;
    color: #f9f9f9;
}

.measure-form-container {
    padding-top: 52px;
}
