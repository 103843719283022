@font-face {
    font-family: 'gilroy-semibold';
    src: url('assets/fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroy';
    src: url('assets/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroy-medium';
    src: url('assets/fonts/Gilroy-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroy-bold';
    src: url('assets/fonts/Gilroy-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gilroy-extrabold';
    src: url('assets/fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

:root {
    --deep-grey-700: #171f29;
    --deep-grey-400: #6e757d;
}

* {
    font-family: 'gilroy', Arial, Helvetica, sans-serif;
}

html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: grey;
    margin: 0;
    padding: 0;
}

video {
    object-fit: cover !important;
}

button {
    outline: none;
    border: none;
    cursor: pointer;
}

h2 {
    font-family: 'gilroy-semibold', Arial, Helvetica, sans-serif;
    font-size: 26px;
    letter-spacing: -0.13px;
}

ol {
    padding-left: 16px;
    font-family: 'gilroy-semibold', serif !important;
    line-height: 1.33;
    letter-spacing: 0.04px;
    font-size: 18px;
    color: #f9f9f9;
}

li {
    margin-bottom: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #535b63;
}

input[type='date']::-webkit-credentials-auto-fill-button {
    visibility: hidden;
}

.MuiAutocomplete-endAdornment > button {
    background-color: #fff !important;
}

.MuiOutlinedInput-input:-webkit-autofill {
    -webkit-text-fill-color: #434b55 !important;
}

.MuiOutlinedInput-input {
    font-size: 18px;
}

.MuiInputLabel-outlined.MuiInputLabel-root {
    font-family: 'gilroy-medium', serif;
    font-size: 18px;
}

.MuiAutocomplete-listbox {
    background: white;
    color: black;
}

.MuiAutocomplete-noOptions {
    color: rgb(105 87 87 / 70%) !important;
    padding: 14px 16px;
    background: white;
}

.deep-grey-700 {
    color: #171f29 !important;
}

.deep-grey-600 {
    color: #1d2733 !important;
}

.deep-grey-400 {
    color: #6e757d !important;
}

.logo {
    height: 36px;
    width: 36px;
    background-image: url('assets/images/logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.star-ratings {
    width: 100%;
    justify-content: center;
    display: flex !important;
}

.container {
    background-image: url('assets/images/BG_\ Home\ Scren\ Blurred\ \(Dark\).png');
    flex: 1;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 32px;
}

.room-connected {
    height: 100vh;
    background-image: url('assets/images/BG_\ Home\ Scren\ Blurred\ \(Dark\).png');
    background-size: cover;
}

.room-disconnected {
    background-image: url('assets/images/BG_\ Home\ Scren\ Blurred\ \(Dark\).png');
    background-size: cover;
}

.round-logo {
    height: 72px;
    width: 72px;
    border-radius: 72px;
    background-color: white;
    background-image: url('assets/images/logo.png');
    background-size: 50%;
    margin: 16px;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 6px 0 grey;
}

.loading-logo {
    background-image: url('https://www.genpact.com/a/dist/img/fancy-loading.gif');
}

.title {
    font-family: 'gilroy-bold', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
}

.subtitle {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #e8e9eb;
    letter-spacing: 0.003em;
}

.button {
    width: 100%;
    max-width: 1024px;
    background: #d1007e;
    border-radius: 100px;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding: 16px;
}

.link-button {
    width: 100%;
    max-width: 1024px;
    color: #d1007e;
    font-family: 'gilroy-semibold', serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    letter-spacing: 0.004em;
    background-color: white;
}

.link-button span {
    font-family: 'gilroy-semibold', serif !important;
}

.secondary-button {
    background: white;
    color: #d1007e;
    border: solid 2px #d1007e;
}

.disabled-button {
    opacity: 1;
    color: #fff;
    background-color: #bbbec2;
    border: none;
    pointer-events: none;
}

.disabled-button.no-fill-button {
    color: #bbbec2;
}

.no-fill-button {
    background: none;
    color: #d1007e;
}

.no-pad-button {
    padding: 0;
}

.narrow-button {
    width: auto;
}

.local-video-container {
    position: relative;
    z-index: 2;
    min-height: 180px;
}

.local-video-connected {
    z-index: 1;
    position: absolute;
    height: 0;
    width: 100%;
    top: 0;
    opacity: 1;
}

.local-video-inner {
    position: absolute;
    width: 92px;
    height: 128px;
    right: 16px;
    top: 16px;
    border-radius: 12px;
    overflow: hidden;
    background-color: black;
}

.local-encounter-video {
    height: 100px;
    width: 100px;
    border-radius: 12px;
}

@supports (-webkit-touch-callout: none) {
    .local-encounter-video {
        position: relative;
        bottom: 40px;
    }
}

.local-video {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    position: absolute;
    width: 92px;
    height: 128px;
    right: 16px;
    top: 16px;
    border-radius: 12px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: grey;
}

.local-video-inner .local-video {
    right: 0;
    top: 0;
}

.local-video > video {
    height: 128px !important;
    width: 92px !important;
}

.showControls {
    bottom: 30px !important;
}

@media (max-height: 599.95px) {
    .showControls {
        bottom: 7px !important;
    }
}

@media (max-height: 559.95px) {
    .showControls {
        bottom: 7px !important;
    }

    .container {
        padding-top: 0;
        padding-bottom: 60px;
    }

    .title {
        font-size: 18px;
        line-height: 20px;
    }

    .button {
        font-size: 16px;
        padding: 12px;
    }
}

.audioLevel-indicator {
    position: absolute;
    top: 117px;
    right: 84px;
    z-index: 1;
    display: none;
}

.video-paused-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 0.003em;
    position: absolute;
    align-self: center;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-responsive-modal-modalCenter > * {
    margin-bottom: 16px;
}

.react-responsive-modal-modalCenter {
    border-radius: 5px;
    padding-bottom: 16px !important;
    display: flex;
    padding-top: 32px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #434b55 !important;
}

.MuiInputBase-root {
    color: #434b55 !important;
}

.MuiFormLabel-root {
    color: #6e757d !important;
}

input[type='date'].MuiFormLabel-root {
    color: red !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #171f29 !important;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiFormGroup-root {
    flex-direction: row !important;
}

.blue-200 {
    color: #7a7fed;
}

.blue-800-bg {
    background-color: #1b1c53 !important;
}

.blue-400-bg {
    background-color: #3639ab !important;
}

.red-500 {
    color: #d1007e;
}

.teal-600 {
    color: #1d827c;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-formControl,
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-family: 'gilroy-medium', serif;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: white;
    padding-right: 3px;
}

.accordion-summary {
    display: none !important;
}

#flip-camera-icon {
    height: 56px !important;
    width: 56px !important;
    margin: 8px !important;
}

h3.seconds {
    font-size: 16px !important;
    color: white !important;
}

.react-timer {
    padding: 0 !important;
    height: 24px;
}

.react-timer > button {
    display: none;
}

.controls-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.controls-info-container {
    margin-bottom: 16;
    font-family: 'gilroy-semibold', serif;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.check-in-header {
    height: 80px;
    align-items: center;
    display: flex;
    background-color: #171f29;
    padding-left: 28px;
}

.partner-header {
    height: 80px;
    align-items: center;
    display: flex;
    background-color: #fff;
    padding-left: 28px;
    justify-content: space-between;
    padding-right: 28px;
    border-bottom-width: 1px;
    border-bottom-color: #e8e9eb;
    border-bottom-style: solid;
}

.withings-logo-container {
    display: flex;
    width: 400px;
    height: 80px;
    align-items: center;
    margin-left: 4vw;
    color: #ffffff;
    white-space: nowrap;
}

.withings-logo-container > img {
    height: 100%;
    margin-left: 16px;
}

.source-org-logo-container {
    justify-content: center;
    color: rgb(23, 31, 41);
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    align-items: center;
}

.source-org-logo-container > img {
    height: 34px;
    margin-left: 8px;
}

.horizontal-logo {
    height: 24px;
    width: 182px;
    background-image: url('assets/images/HB_logo_horizontal_white.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.horizontal-logo-dark {
    height: 24px;
    width: 182px;
    background-image: url('assets/images/HB_logo_horizontal.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.container-600 {
    max-width: 600px;
    background-color: white;
    width: 100%;
    padding: 16px 32px;
    align-self: center;
    box-shadow:
        0 1px 2px 0 rgba(6, 8, 10, 0.25),
        0 0 1px 0 rgba(6, 8, 10, 0.15);
}

.auth0-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.auth0-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    color: #171f29;
}

.auth0-loader > h1 {
    font-family: 'gilroy-bold', Arial, Helvetica, sans-serif;
}

.auth0-loader > h3 {
    font-family: 'gilroy-medium', Arial, Helvetica, sans-serif;
}

.verification-code-modal {
    width: 100%;
}

.withings-container {
    display: flex;
    min-height: calc(100vh - 80px);
}

.withings-container > main {
    flex: 0.6;
    padding: 16px;
    background-image: linear-gradient(#ffffff, #e8e9eb);
    display: flex;
    justify-content: center;
}

.withings-container > .withings-cover-image {
    flex: 0.4;
    background-image: url('assets/images/withings_ecg_device.png');
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
}

.overflow-auto {
    overflow: auto;
}

.dropzone {
    width: 100%;
}

.answer-radio {
    background: white !important;
    color: #171f29 !important;
    margin-left: -12px !important;
}

.answer-radio .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
}

.atrium-check-in-body h1 {
    font-family: 'gilroy-semibold', serif;
    font-size: 26px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--deep-grey-700);
}

.atrium-check-in-body p {
    font-family: 'gilroy-medium', serif;
    font-size: 20px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 28px;
    color: var(--deep-grey-400);
}

.hero-heading {
    letter-spacing: -1.28px;
    font-size: 60px;
    font-family: 'gilroy-bold', serif;
    color: #171f29;
    font-weight: 800;
}

.container-two a,
.container-one a,
.withings-terms a {
    text-decoration: none;
    color: #d1007e;
}

.faq-text {
    color: #171f29;
    font-size: 18px;
    font-family: 'gilroy-medium', serif;
}

.faq-text > a {
    font-family: 'gilroy-bold', serif;
}

@media screen and (max-width: 768px) {
    .container-one {
        padding-top: 0;
        padding-bottom: 0;
        justify-content: flex-start;
    }
    .container-two,
    .container-one {
        height: auto;
    }
    .hero-heading {
        font-size: 48px;
    }
    .check-in-header {
        flex-direction: column;
        height: auto;
        padding: 24px;
        justify-content: start;
        align-items: start;
    }
    .withings-logo-container {
        flex-direction: column;
        margin-top: 36px;
        margin-left: 0;
        width: 100%;
        height: 100%;
        align-items: flex-end;
    }
    .withings-logo-container > img {
        height: 48px;
        margin-left: 0;
    }
}

@media screen and (max-width: 1024px) {
    .withings-container > main {
        flex: 1;
    }
    .withings-container > .withings-cover-image {
        display: none;
    }
}
