.invitation-contanier {
    overflow: scroll;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 16px;
    padding-bottom: 60px;
    flex-direction: column;
}

.invitation-title {
    color: black;
    font-size: 36px;
    margin-top: 16px;
    margin-bottom: 32px;
    line-height: 40px;
}

.invitation-button-container {
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    display: flex;
}

.invitation-buttons-container {
    width: 100%;
    margin-top: 24px;
}
